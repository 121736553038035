/* Fonctionnement.css */

.fonctionnement {
  font-family: Arial, sans-serif;
  color: #333;
  padding: 20px;
  background: linear-gradient(to right, #c2e9fb, #c3fda1);
  display: flex;
  flex-direction: column;
  align-items: center; /* Ajout pour centrer le contenu verticalement */
}

.section {
  background: #fff;
  margin-bottom: 20px;
  width: 100%;
  max-width: 800px; /* Réduction de la largeur maximale pour une meilleure lisibilité */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.section h1,
.section h2 {
  color: #444;
}

.section p,
.section ul {
  margin: 10px 0; /* Ajustement des marges pour un espacement uniforme */
  line-height: 1.6;
}

.section ul {
  padding-left: 20px;
  list-style-type: disc; /* Utilisation de puces pour les listes non ordonnées */
}

.section li {
  text-align: left; /* Alignement du texte à gauche dans les listes */
}

.icon-text {
  display: flex;
  align-items: center; /* Ajustement pour aligner les icônes et le texte */
  margin-bottom: 20px;
}

.icon {
  font-size: 30px;
  color: #333;
  margin-right: 10px; /* Espace entre l'icône et le texte */
}
