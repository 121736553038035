/* Acces.css */
.acces {
  background: linear-gradient(to right, #ccf4c5, #f4d9c5);
 
  height: 100%;
  color: #252525;
  font-family: Arial, sans-serif;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.acces h1 {
  margin-top: 30px;
  text-align: center;
}

.acces h1, .acces h2 {
  color: #333;
}

.acces p, .acces ul {
  line-height: 1.6;
  margin: 20px 0;
}

.acces ul {
  list-style-type: none;
  padding: 0;
}

.acces li {
  margin-bottom: 10px;
}

.map-container {
  width: 100%;
  height: 400px;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .acces {
    padding: 10px;
  }

  .map-container {
    height: 300px;
  }
}
