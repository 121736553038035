/* header.css */

.header {
  position: relative;
  top: 0;
  z-index: 4;
  background-color: #333;
  color: #fff;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.header:hover {
  background-color: #000000; /* Couleur de fond légèrement plus foncée au survol */
}

.header-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.menu-toggle {
  font-size: 24px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  display: none; /* Cacher par défaut */
  transition: color 0.3s ease;
}

.menu-toggle:hover {
  color: #ddd; /* Couleur du bouton légèrement plus claire au survol */
}

.nav-menu {
  display: flex;
  align-items: center;
}

.nav-list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin: 0 10px;
  align-self: center;
  transition: transform 0.3s ease;
}

.nav-item:hover {
  transform: translateY(-2px); /* Animation de légère élévation au survol */
}

.nav-link {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #ddd; /* Couleur du lien légèrement plus claire au survol */
}

.logout-button,
.login-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

.logout-button:hover,
.login-button:hover {
  background-color: #45a049; /* Couleur de fond légèrement plus foncée au survol */
  transform: translateY(-2px); /* Animation de légère élévation au survol */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Ombre plus prononcée au survol */
}

.logout-button:active,
.login-button:active {
  background-color: #3e8e41; /* Couleur de fond au clic */
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Ombre légère au clic */
}

.error-message {
  color: #333;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-login {
  background: white;
  padding: 20px;
  display: flex;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-close {
  position: absolute;
  top: 0px;
  right: 0px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.login-form input {
  width: 80%;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  align-self: center;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.login-form input:focus {
  border-color: #4caf50; /* Couleur de bordure au focus */
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5); /* Lueur légère au focus */
  outline: none;
}

.login-form button {
  padding: 10px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

.login-form button:hover {
  background-color: #45a049; /* Couleur de fond légèrement plus foncée au survol */
  transform: translateY(-2px); /* Animation de légère élévation au survol */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Ombre plus prononcée au survol */
}

.login-form button:active {
  background-color: #3e8e41; /* Couleur de fond au clic */
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Ombre légère au clic */
}

/* Styles pour le menu déroulant en mode mobile */
@media (max-width: 768px) {
  .menu-toggle {
    display: block; /* Afficher le bouton de menu en mode mobile */
  }

  .nav-menu {
    display: none;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    background-color: #333;
    text-align: center;
    padding: 20px 0;
    transition: transform 0.3s ease;
    z-index: 3;
  }

  .nav-menu.open {
    display: flex; /* Afficher le menu lorsqu'il est ouvert */
    flex-direction: column;
    transform: translateY(0);
  }

  .nav-list {
    flex-direction: column;
  }

  .nav-item {
    margin: 10px 0;
    transition: transform 0.3s ease;
  }

  .nav-item:hover {
    transform: translateY(-2px); /* Animation de légère élévation au survol */
  }
}
