/* Home.css */
.home {
  font-family: Arial, sans-serif;
  color: #000000;
  padding: 20px;
  background: linear-gradient(to right, #afafcf, #e0eafc);
}

.home a {
  width: 25%;
  text-decoration: none;
}

.content-container {
  background: #ffffff00;
  margin: 20px;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-container {
  margin-bottom: 20px;
  width: 100%;
  padding: 0 20px;
}

.text-container h2 {
  text-align: center;
  color: #444;
}

.text-container p {
  text-align: justify;
  line-height: 1.6;
}

.card-grid-container, .gallery-container {
  margin-top: 40px;
  width: 100%;
  padding: 0 20px;
}

.card-grid-container h2, .gallery-container h2 {
  text-align: center;
  color: #444;
  margin-bottom: 20px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .home {
    padding: 10px;
  }
  .home a {
    width: 100%;
    display: contents;
  justify-content: center;
    text-decoration: none;
  }
  .text-container h2, .card-grid-container h2, .gallery-container h2 {
    font-size: 1.5rem;
  }

  .text-container p {
    font-size: 1rem;
  }

  .card-grid-container, .gallery-container {
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .text-container h2, .card-grid-container h2, .gallery-container h2 {
    font-size: 1.25rem;
  }
  .home a {
    width: 100%;
    text-decoration: none;
  }
  .text-container p {
    font-size: 0.875rem;
  }
}
