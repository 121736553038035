/* Pedagogie.css */
.pedagogie-page {
  font-family: Arial, sans-serif;
  color: #333;
  padding: 20px;
  background: linear-gradient(to right, #e0eafc, #a7cde4);
  display: flex;
  flex-direction: column;
  align-items: center;
}
