/* SuccessRateComponent.css */

.success-rate-container {
  display: flex;
  flex-direction: column-reverse;
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.success-rate-container:hover {
  transform: translateY(-5px); /* Animation de légère élévation au survol */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Ombre plus prononcée au survol */
}

form {
  margin-bottom: 20px;
}

input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(50% - 10px);
  transition: border-color 0.3s ease;
}

input[type="submit"] {
  width: 100%;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

input:focus {
  outline: none;
  border-color: #007bff; /* Couleur de bordure au focus */
}

input[type="submit"]:hover {
  background-color: #0056b3; /* Couleur de fond légèrement plus foncée au survol */
}

.success {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.success-rate {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
}

.success-rate .additional-info {
  margin-bottom: 10px;
}

.success-rate .success-rate-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.success-rate .success-rate-percent {
  font-size: 24px;
}

.success-rate .progress-bar {
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
}

.success-rate .progress-fill {
  height: 100%;
  background-color: #28a745;
  transition: width 0.5s ease; /* Animation pour la barre de progression */
}

.success-rate .collapse-content {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ccc;
}

.success-rate .collapse-content ul {
  padding-left: 20px;
}

.success-rate .collapse-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.success-rate .collapse-button:hover {
  background-color: #0056b3; /* Couleur de fond légèrement plus foncée au survol */
}

.delete-button-results {
  background-color: #dc3545;
  color: white;
  border: none;
  margin-bottom: 15px;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.delete-button-results:hover {
  background-color: #c82333; /* Couleur de fond légèrement plus foncée au survol */
}
