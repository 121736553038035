.inscription {
  padding: 20px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #f4d9c5, #f7aaaa);
  align-items: center;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 20px;
  max-width: 1000px;
}

.email-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
}

.email-form label {
  margin-bottom: 10px;
  font-weight: bold;
}

.email-form input,
.email-form textarea {
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 1000px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.email-form button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.email-form button:hover {
  background-color: #0056b3;
}
