.minimal-carousel {
  z-index: 0;
  position: relative;
  width: 100%;
  height: 300px; 
  
}

.carousel-images {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.carousel-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  height:15%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.454);
  border-radius: 10px;
  border: none;
  color: #e7e6e6;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.carousel-arrow.prev {
  left: 10px;
}

.carousel-arrow.next {
  right: 10px;
}

.carousel-arrow:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.carousel-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: xx-large;
  color: #ffffff;
}

.carousel-text h2 {
  font-size: 24px;
  margin: 0;
}

