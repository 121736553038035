/* Gallery.css */

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Espace entre les images */
}

.image-container {
  position: relative;
  width: 150px; /* Largeur fixe des conteneurs d'image */
  height: 150px; /* Hauteur fixe des conteneurs d'image */
  overflow: hidden; /* Cacher tout débordement */
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajuster et couper l'image pour remplir le conteneur */
  border-radius: 8px; /* Coins arrondis */
  cursor: pointer;
  transition: transform 0.3s ease; /* Animation pour le hover */
}

.image-container img:hover {
  transform: scale(1.05);
}

.image-container button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  color: #333;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.image-container button:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

/* Modal Content */

.modal-content {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9); /* Fond semi-transparent pour la modal */
  padding: 20px; /* Ajoutez du padding pour l'espace intérieur */
  border: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Assure que la modal est au-dessus du reste du contenu */
  overflow: auto; /* Permet le défilement si l'image est plus grande que l'écran */
  box-sizing: border-box; /* Inclus la bordure */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Ombre pour la modal */
}

.modal-content img {
  max-width: calc(100vw - 40px); /* Largeur maximale de l'image, tenant compte du padding */
  max-height: calc(100vh - 40px); /* Hauteur maximale de l'image, tenant compte du padding */
  object-fit: contain; /* Ajuster l'image sans déformation */
  border-radius: 8px; /* Coins arrondis pour l'image */
  transition: transform 0.3s ease; /* Animation pour le hover */
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 20px;
  font-size: xx-large;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 1000; /* Assure que le bouton de fermeture est au-dessus de la modal */
}

.navigation-buttons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px); /* Largeur maximale de la barre de navigation */
  padding: 0 20px; /* Espacement pour les flèches */
  box-sizing: border-box;
}

.navigation-buttons button {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navigation-buttons button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.left-arrow {
  margin-left: 10px; /* Espacement à gauche pour la flèche gauche */
}

.right-arrow {
  margin-right: 10px; /* Espacement à droite pour la flèche droite */
}

/* Media Queries pour rendre la galerie responsive */

@media (max-width: 1200px) {
  .image-container {
    width: 120px; /* 3 images par ligne */
    height: 120px;
  }
}

@media (max-width: 992px) {
  .image-container {
    width: 45%; /* 2 images par ligne */
    height: 150px;
  }
}

@media (max-width: 768px) {
  .image-container {
    width: 100%; /* 1 image par ligne */
    height: 200px;
  }
}
