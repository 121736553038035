/* Style de base du footer */
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
}

/* Liens dans le footer */
.footer a {
  color: aliceblue; /* Supprime le soulignement par défaut */
}

.footer a:hover {
  color: rgb(146, 4, 4);
}

/* Conteneur du footer avec une largeur maximale de 1200px centré */
.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

/* Chaque section du footer avec flex-grow pour occuper l'espace disponible */
.footer-section {
  flex: 1;
  min-width: 200px; /* Largeur minimale pour éviter un rétrécissement excessif */
}

/* Titres des sections avec une taille de police de 20px */
.footer-section h3 {
  font-size: 20px;
}

/* Paragraphes et listes sans marges ni padding par défaut */
.footer-section p,
.footer-section ul {
  margin: 0;
  padding: 0;
}

/* Liens sociaux avec flexbox pour les aligner horizontalement */
.social-links {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 10px;
}

/* Styles des liens sociaux */
.social-links a {
  color: #fff;
  font-size: 20px;
}

/* Informations de contact sans list-style, marges contrôlées */
.contact-info {
  list-style: none;
  padding: 0;
}

.contact-info li {
  margin-bottom: 10px;
}

.contact-info svg {
  margin-right: 5px;
}

/* Media query pour les écrans jusqu'à 768px de largeur */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column; /* Met les sections en colonne */
    align-items: center; /* Centre les éléments horizontalement */
  }

  .footer-section {
    text-align: center; /* Centre le texte dans les sections */
  }

  .contact-info,
  .social-links {
    justify-content: center; /* Centre les éléments horizontalement */
  }
}
