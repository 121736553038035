.error-container {
    width: 100%;
    border-radius: 4px;
    text-align: center;
  }
  
  .error-container h1 {
    font-size: 36px;
    color: #221d1d;
    margin-bottom: 20px;
  }
  
  .error-container p {
    font-size: 18px;
    color: #555555;
    margin-bottom: 20px;
  }
  
  .error-container a {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ff4444;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .error-container a:hover {
    background-color: #cc0000;
  }
  