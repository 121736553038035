/* ResultatBrevet.css */
.resultat-brevet {
    font-family: Arial, sans-serif;
    padding: 20px;
    background: linear-gradient(to right, #c3fda1, #f4d9c5);
  }
  
  .resultat-brevet h1 {
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
  }
  
  .resultat-brevet p {
    text-align: center;
    color: #242323;
    margin-bottom: 30px;
  }
  
  .success-rate-list {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  