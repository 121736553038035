/* SectionCard.css */

.section-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    min-width: 70%;
    max-width: 800px;
    align-self: center;
  }
  
  .section-card h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .section-card .icon {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .section-card p {
    line-height: 1.6;
  }
  
  .section-card textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .section-card button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  .section-card button:hover {
    background-color: #0056b3;
  }
  
  .section-card button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .section-card button:disabled:hover {
    background-color: #ccc;
  }
  