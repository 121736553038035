/* Styles pour .CardImaged-container */

.CardImaged-container {
  display: flex;
  flex-direction: column;
}

.CardImaged {
  display: flex;
  flex-direction: row;
  background-color: whitesmoke;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1200px;
}

.CardImaged img {
  width: 30%;
  object-fit: cover;
  max-height: 370px;
  transition: max-height 0.3s ease;
}

.CardImaged-content {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.CardImaged-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.CardImaged-description {
  line-height: 1.5;
  margin-bottom: 20px;
}

.CardImaged button {
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 10px;
  margin-top: 10px;
  transition: opacity 0.3s ease;
}

.CardImaged button:hover {
  opacity: 0.8;
}

.zone-bouton {
  position: relative;
  top: 0;
}

.CardImaged .delete-button {
  background-color: #dc3545;
}

.CardImaged .edit-button {
  background-color: #28a745;
}

.error,
.success {
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
}

.error {
  background-color: #dc3545;
  color: #fff;
}

.success {
  background-color: #28a745;
  color: #fff;
}

/* Media Queries for Responsivity */

@media (max-width: 1220px) {
  .CardImaged {
    max-height: 450px;
  }

  .CardImaged img {
    max-height: 400px;
  }
}

@media (max-width: 1020px) {
  .CardImaged {
    max-height: 390px;
  }
}

@media (max-width: 850px) {
  .CardImaged {
    flex-direction: column;
    height: auto;
    max-height: none;
  }

  .CardImaged img {
    width: 100%;
    height: auto;
    max-height: 200px;
  }
}
